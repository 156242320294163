<template>
	<v-row justify="center">
		<v-card>
			<v-card-title>Авторизация </v-card-title>
			<v-card-text>
				<v-form @submit.prevent='login'>
					<v-container>
						<v-row>
							<v-col>
								<v-text-field label='Логин' type="text" v-model='username' required></v-text-field>
								<v-text-field 
									v-model="password" 
									:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
									:rules="[rules.required, rules.min]" 
									:type="show1 ? 'text' : 'password'" 
									name="input-10-1" 
									label="Пароль" 
									hint="At least 8 characters" 
									counter 
									@click:append="show1 = !show1"
								></v-text-field>
								<v-btn type='submit'>Авторизация</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
		</v-card>
	</v-row>
</template>


<script type="text/javascript">

export default {
	name:'Login',
	data() {
		return {
			username: 'chistov',
			password:'',
			show1: false,
			rules: {
				required: value => !!value || 'Required.',
				min: v => v.length >= 8 || 'Min 8 characters',
				emailMatch: () => (`The email and password you entered don't match`),
			},
		}
	},
	methods: {
		login(){
			let username = this.username
			let password = this.password
			
			this.$store.dispatch('login', {username, password})
			.then((res)=>{
				//this.$router.push({'name':'Staff'}).catch((err)=>{ })
				console.log(this.$router)
				//this.$router.push({path:'/llk'})
				//this.$router.push('/').catch((err)=>{ console.log(err) })
				console.log('Login:login OK:',res)
				//console.log(this)
			})
			.catch((err)=>{ 
				console.log('Ошибка авторизации')
				console.log(err) 
			})
		}
	}
}
	
</script>

<style scope>
</style>
